var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"attachment-list attachment-list-variables"},[(_vm.isLoading)?_c('aula-spinner'):_vm._e(),_vm._v(" "),_vm._l((_vm.attachments),function(attachment,id){return [(
        !_vm.isLoading &&
        attachment.status != _vm.attachmentStatus.UNAVAILABLE &&
        (attachment.media == null || (_vm.showMedia && attachment.media != null))
      )?_c('div',{key:id,staticClass:"media-data-container",attrs:{"tabindex":"0","role":"button"},on:{"click":function($event){return _vm.onAttachmentClick(attachment)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onAttachmentClick(attachment)},"contextmenu":_vm.onAttachmentRightClick}},[_c('div',{staticClass:"album-media-data"},[_c('div',{staticClass:"media-item",class:{
            disabled: _vm.checkIsDocumentDisabled(attachment) === true,
          }},[_c('div',{staticClass:"media-thumbnail",class:{
              disabled: _vm.checkIsDocumentDisabled(attachment) === true,
            }},[_c('i',{class:attachment.link != null && attachment.link.service == 'OneDrive'
                  ? 'icon-onedrive'
                  : attachment.link != null && attachment.link.service == 'Google Drive'
                  ? 'icon-googledrive'
                  : 'icon-Aula_note'})]),_vm._v(" "),_c('div',{staticClass:"media-name"},[[_vm._v("\n              "+_vm._s(_vm.getAttachmentTitle(attachment))+"\n            ")],_vm._v(" "),(attachment.status != null && attachment.status == _vm.attachmentStatus.PENDING)?[(attachment.media != null)?[_vm._v("\n                ("+_vm._s(_vm._f("fromTextKey")('ATTACHMENT_MEDIA_STATUS_PENDING'))+")\n              ")]:[_vm._v(" ("+_vm._s(_vm._f("fromTextKey")('ATTACHMENT_FILE_STATUS_PENDING'))+")")]]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"px-2"},[_vm._v("\n            "+_vm._s(_vm._f("fromTextKey")('OPEN'))+"\n            "),_c('Icon',{staticClass:"pl-1",attrs:{"name":_vm.iconClassEnum.EXPORT}})],1)])])]):_vm._e()]}),_vm._v(" "),_c('aula-modal',{ref:"externalLinkWarningModal",on:{"cancelClicked":function($event){return _vm.$refs.externalLinkWarningModal.hide()},"okClicked":function($event){_vm.openExternalLink();
      _vm.$refs.externalLinkWarningModal.hide();}}},[_vm._v("\n    "+_vm._s(_vm._f("fromTextKey")('WARNING_OPEN_EXTERNAL_LINK_1'))),_c('br'),_c('br'),_vm._v("\n    "+_vm._s(_vm._f("fromTextKey")('WARNING_OPEN_EXTERNAL_LINK_2'))+"\n  ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }