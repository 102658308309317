<template>
  <div class="editor-container">
    <div v-if="!readOnly" class="aula_editor" :class="{ folding: isFolding }">
      <div v-show="editorInitialized" :ref="'editorRoot'">
        <textarea :id="id" :value="value" />
      </div>
      <b-alert variant="danger" :show="maxLength != 0 && currentLength > maxLength - 10 && !hideAlert">
        {{ 'MESSAGE_VALIDATE_TEXT_LENGTH' | fromTextKey }}
      </b-alert>
    </div>
    <div v-else class="aula_editor_readonly" v-html="value" />
    <toastr ref="addedToaster" variant="success" :show-icon="true" icon="icon-Aula_check">
      {{ 'SECURE_DOCUMENT_ADDED_WAIT' | fromTextKey }}
    </toastr>
  </div>
</template>

<script>
// TinyMCE Default imports
import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver/theme';
import 'tinymce/icons/default';
import './aulaIcons';
import './emojis';

// Any plugins you want to use has to be imported
import 'tinymce/plugins/lists';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/paste';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/colorpicker';
import 'tinymce/plugins/link';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/table';
import 'tinymce/plugins/media';
import 'tinymce/plugins/image';
import 'tinymce/plugins/hr';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/help';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/fullscreen';

import './plugins/autolinkfixed';
import './plugins/editorKeepAlive';
import './plugins/foldFonts';
import './plugins/skoletube';
import './plugins/bornetube';
import './plugins/youtube';

import './translations/da';

import { foldFonts } from './commands/foldFonts';

import '../../assets/css/tinymce/skins/content/Aula/content.css';
import '../../assets/css/tinymce/skins/ui/Aula/skin.css';
import { editorToolbarItems } from '../../enums/editorToolbarItems';
import $ from 'jquery';

export default {
  name: 'TinyMce',
  props: {
    draggable: {
      type: Boolean,
      default: false,
    },
    hideAlert: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    fixedSize: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: false,
    },
    clean: {
      type: Boolean,
      default: false,
    },
    toolbarType: {
      type: String,
      default: 'basic',
      validator: value => ['basic', 'note', 'extended', 'advanced'].includes(value.toLowerCase()),
    },
    id: {
      type: String,
      default: 'editor',
    },
    value: {
      type: String,
      default: '',
    },
    toolbar: {
      type: Array,
      default: () => [],
    },
    menubar: {
      default: '',
    },
    otherProps: {
      default: '',
    },
    baseURL: {
      type: String,
      default: '',
    },
    placeholderTextkey: {
      type: String,
      default: '',
    },
    ariaLabel: { type: String, default: 'ARIA_LABEL_WRITE_CONTENT' },
    maxResizeHeight: {
      type: Number,
      default: 500,
    },
    maxLength: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    objectResizing: {
      type: [String, Boolean],
      default: false,
      validator: objectResizing => {
        if (typeof objectResizing === 'boolean') {
          return true;
        }

        return objectResizing === 'img';
      },
    },
    hasEmojis: {
      type: Boolean,
      default: true,
    },
    plugins: { type: Array, default: () => [] },
  },
  data() {
    return {
      initSet: false,
      currentLength: 0,
      editor: {},
      editorInitialized: false,
    };
  },
  computed: {
    bodyClass() {
      const classes = [];
      if (['note', 'extended', 'advanced'].includes(this.toolbarType)) {
        classes.push('document');
      }
      return classes;
    },
    textTooLong() {
      return this.maxLength > 0 && this.currentLength > this.maxLength - 10;
    },
    editorPlugins() {
      return [
        'autoresize',
        'paste',
        'emoticons',
        'lists',
        'image',
        'textcolor',
        'colorpicker',
        'foldFonts',
        'link',
        'autolinkFixed',
        'editorKeepAlive',
        'table',
        'hr',
        'insertdatetime',
        'help',
        'charmap',
        'searchreplace',
        'fullscreen',
        ...this.plugins,
      ];
    },
    toolbarItems() {
      if (this.toolbar.length > 0) {
        return this.toolbar;
      }

      switch (this.toolbarType) {
        case 'basic':
          return editorToolbarItems.BASIC;
        case 'note':
          return editorToolbarItems.NOTE;
        case 'extended':
          return editorToolbarItems.EXTENDED;
        case 'advanced':
          return editorToolbarItems.ADVANCED;
      }

      return 'basic';
    },
    isFolding() {
      return this.toolbarType === 'basic' || this.toolbarType === 'note';
    },
    hasTablePlugin() {
      for (const toolbarItem of this.toolbarItems) {
        if (toolbarItem.includes('table')) {
          return true;
        }
      }
      return false;
    },
    tableToolbarOptions() {
      if (this.hasTablePlugin) {
        // undefined means that it will move to default option from tinyMCE
        return undefined;
      }
      // Empty string means to disable table toolbar options
      return '';
    },
    contextMenuSettings() {
      const settings = ['image', 'table'];

      if (!this.hasTablePlugin) {
        const tableIndex = settings.indexOf('table');
        settings.splice(tableIndex, 1);
      }

      return settings.join(' ');
    },
  },
  watch: {
    clean: function () {
      if (this.clean) {
        tinymce.activeEditor.setContent('');
      }
    },
    disabled(isDisabled) {
      this.setEditorEnabled(isDisabled === false);
    },
    readOnly() {
      this.setEditorEnabled(this.disabled === false);
    },
  },
  mounted() {
    const vm = this;
    let toolbar = this.toolbar;
    if (toolbar.length === 0) {
      toolbar = this.toolbarItems;
    }

    this.$nextTick(() => {
      this.editorInitialized = true;
      this.setEditorEnabled(this.disabled === false);
    });

    if (this.value && this.value != '') {
      this.currentLength = this.value.length;
      if (this.maxLength != 0 && this.currentLength > this.maxLength - 10) {
        this.$emit('textTooLong', true);
      } else {
        this.$emit('textTooLong', false);
      }
    }

    tinymce.baseURL = this.baseURL;
    tinymce.init({
      resize: this.draggable,
      // // Disable visual helpers for tables and images
      visual: false,
      object_resizing: this.objectResizing,
      table_toolbar: this.tableToolbarOptions,
      contextmenu: this.contextMenuSettings,
      placeholder: vm.$options.filters.fromTextKey(vm.placeholderTextkey),
      deprecation_warnings: false,
      forced_root_block: 'div',
      icons: 'Aula',
      browser_spellcheck: true,
      font_formats: "Lato='Lato', sans-serif;Courier New=courier new",
      auto_focus: this.autoFocus ? `${this.id}` : undefined,
      default_link_target: '_blank',
      link_context_toolbar: true,
      link_assume_external_targets: 'https',
      convert_urls: true,
      relative_urls: false,
      remove_script_host: false,
      selector: `#${this.id}`,
      skin: false,
      branding: false,
      elementpath: false,
      language: 'da',
      paste_data_images: false,
      lineheight_formats: '1 1.15 1.5 2 2.5 3',
      insertdatetime_formats: ['%d. %b %Y', '%d. %b %Y kl. %H:%M'],
      image_uploadtab: true,
      images_upload_handler: function (blobInfo, success, failure) {
        const base64image = blobInfo.base64();
        if (base64image.length >= 300000) {
          failure(vm.$options.filters.fromTextKey('UPLOAD_MODAL_ERROR_MAX_SIZE_BODY'));
        }
        success('data:' + blobInfo.blob().type + ';base64,' + blobInfo.base64());
      },
      oninit: 'setPlainText',
      plugins: this.editorPlugins,
      statusbar: false,
      toolbar,
      table_default_styles: {
        'word-break': 'break-word',
        width: '50%',
      },
      content_css: '/static/css/EditorContent.css?' + new Date().getTime(), // AULA-37426: Add time to manually clear browser cache {@link https://www.tiny.cloud/docs/configure/content-appearance/#content_css}
      body_class: this.bodyClass,
      style_formats: [
        {
          title: 'Folding block header',
          inline: 'span',
          classes: 'folding-block-header',
        },
        {
          title: 'Folding block content',
          inline: 'span',
          classes: 'folding-block-content',
        },
      ],
      fontsize_formats: '8pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt',
      menubar: this.menubar,
      autoresize_max_height: this.maxResizeHeight,
      autoresize_bottom_margin: 10,
      help_tabs: ['shortcuts', 'keyboardnav'],
      setup: function (ed) {
        ed.addCommand('foldFonts', () => foldFonts(ed));
      },
      init_instance_callback: editor => {
        if (this.fixedSize) {
          $(editor.iframeElement).contents().find('body').css('min-height', 48);
        }
        $(editor.getBody()).attr('aria-label', this.$options.filters.fromTextKey(this.ariaLabel));
        editor.on('keyup', event => {
          this.$emit('input', editor.getContent());
          this.$emit('textTooLong', vm.textTooLong);
          this.handleEscapeKeyPressed(event);
        });
        editor.on('PastePreProcess', e => {
          this.stripInvalidHTML(e);
        });
        editor.on('PastePostProcess', e => {
          this.addBorderToTables(e.node);
        });
        editor.on('change', ev => {
          this.$nextTick(() => {
            if (ev.lastLevel == null) {
              return;
            }
            this.$emit('input', editor.getContent());
            this.$emit('textTooLong', vm.textTooLong);
          });
        });
        editor.on('ObjectResized', ev => {
          if (ev.target.tagName === 'TABLE') {
            setMaxTableWidth(ev.target);
          }
        });
        editor.on('FullscreenStateChanged', this.handleFullScreenStateChanged);
        editor.setContent(this.value);
        this.$emit('initialize', editor);
      },

      ...this.otherProps,
    });
    setTimeout(() => {
      const toolbarButtons = document.getElementsByClassName('mce-custom-editor-button');
      const lastToolbarButton = document.getElementsByClassName('mce-widget mce-btn mce-last')[0];
      const firstToolbarButton = document.getElementsByClassName('mce-widget mce-btn mce-first')[0];
      for (const btn of toolbarButtons) {
        btn.tabIndex = 0;
      }
      if (lastToolbarButton != null) {
        lastToolbarButton.tabIndex = 0;
        lastToolbarButton.addEventListener('keydown', e => {
          if (e.which == 9) {
            lastToolbarButton.blur();
          }
        });
      }
      if (firstToolbarButton != null) {
        firstToolbarButton.addEventListener('keydown', e => {
          if (e.which == 9) {
            firstToolbarButton.blur();
          }
        });
      }
    }, 300);
  },
  beforeDestroy() {
    if ('activeElement' in document) {
      if (document.getElementById('focus-element-adm')) {
        document.getElementById('focus-element-adm').focus();
      }
      if (document.getElementById('focus-element')) {
        document.getElementById('focus-element').focus();
      }
      document.activeElement.blur();
    }
    if (tinymce.get(this.id) != null) {
      tinymce.get(this.id).destroy();
    }
  },
  methods: {
    handleEscapeKeyPressed(event) {
      if (event.code !== 'Escape') {
        return;
      }
      const editor = tinymce.get(this.id);
      if (editor.plugins.fullscreen.isFullscreen()) {
        editor.execCommand('mceFullScreen');
      }
    },
    handleFullScreenStateChanged({ state }) {
      const editor = tinymce.get(this.id);
      if (state === true) {
        editor.dom.addClass(tinymce.activeEditor.dom.select('html'), 'fullscreen');
      } else {
        editor.dom.removeClass(tinymce.activeEditor.dom.select('html'), 'fullscreen');
      }
    },
    stripInvalidHTML(event) {
      if (this.isFolding === true) {
        // Strip all table tags & classes when pasting
        event.content = event.content.replace(/(<\/{0,1}(table|tr|td|th|tbody|thead)[^><]*>|class=".*?")/g, '');
      }
      event.content = event.content.replace(/<img[^>]*>/g, '');
    },
    addBorderToTables(content) {
      const tables = [...content.querySelectorAll('table')];
      tables.forEach(table => table.setAttribute('border', 1));
    },
    focusEditor() {
      tinymce.execCommand('mceFocus', false, this.id);
    },
    insertContent(content) {
      tinymce.activeEditor.execCommand('mceInsertContent', false, '<p>' + content + '</p>');
    },
    setContent(content) {
      tinymce.activeEditor.execCommand('mceReplaceContent', false, content);
    },
    setEditorEnabled(isEnabled) {
      this.$nextTick(() => {
        const editor = tinymce.get(this.id);
        if (editor == null) {
          return;
        }
        if (isEnabled) {
          editor.setMode('design');
        } else {
          editor.setMode('readonly');
        }
      });
    },
  },
};
function setMaxTableWidth(table) {
  const tableWidth = table.getBoundingClientRect().width;
  const parentWidth = table.parentElement.getBoundingClientRect().width;
  const ratio = (tableWidth * 100) / parentWidth;
  if (ratio > 99) {
    table.style.width = '99%';
  }
}
</script>
<style scoped lang="scss">
@import '../../assets/scss/core/variables.scss';
@import '../../assets/scss/core/breakpoints.scss';

/deep/ .aula_editor .tox.tox-tinymce {
  min-height: 250px;
  overflow: unset;

  @include breakpoint-lg-down() {
    min-height: 200px;
  }

  .tox-editor-header {
    position: sticky;
    top: -1px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: hidden;
  }

  .tox-sidebar-wrap {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
  }

  .tox-editor-container {
    overflow: unset;
  }
}
</style>
