

















import { PropType } from 'vue';
import { PostModel } from '../../../shared/models/post.model';
import PostHeader from './PostHeader.vue';
import PostComments from './PostComments.vue';
import PostBody from './PostBody.vue';
import PostAttachments from './PostAttachments.vue';
import { providerKeyTypes } from '../../../shared/enums/providerKeyTypes.js';
import { notificationAreas } from '../../../shared/enums/notificationAreas';
import { notificationTypes } from '../../../shared/enums/notificationTypes';
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types.js';
import * as dateUtil from '../../../shared/utils/dateUtil.js';

export default {
  components: { PostAttachments, PostBody, PostComments, PostHeader },
  inject: {
    getPostObserver: { from: providerKeyTypes.postObserver, default: () => () => null },
  },
  props: {
    post: { type: Object as PropType<PostModel>, required: true },
    previewMode: { type: Boolean, default: false },
    includesReportedComments: { type: Boolean, default: false },
    highlightedCommentId: { type: Number, default: null },
  },
  emits: ['edit', 'delete', 'report'],
  computed: {
    ...mapGetters({
      notifications: types.GET_NOTIFICATIONS,
    }),
    canViewComments() {
      return this.post.allowComments;
    },
    isUnread() {
      return this.notifications.some(
        notification =>
          notification.notificationArea == notificationAreas.POSTS &&
          notification.notificationType === notificationTypes.BADGE &&
          notification.postId == this.post.id
      );
    },
    isImportant() {
      if (!this.post.isImportant) {
        return false;
      }
      const today = dateUtil.format(new Date());
      const isSameOrAfterImportantFrom =
        !this.post.importantFrom || dateUtil.isBefore(today, this.post.importantFrom, 'days') === false;
      const isSameOrBeforeImportantTo =
        !this.post.importantTo || dateUtil.isAfter(today, this.post.importantTo) === false;
      return isSameOrAfterImportantFrom && isSameOrBeforeImportantTo;
    },
  },
  mounted() {
    this.getPostObserver()?.observe(this.$el);
  },
  methods: {
    emitEdit() {
      this.$emit('edit', this.post.id);
    },
    emitDelete() {
      this.$emit('delete', this.post.id);
    },
    emitReport() {
      this.$emit('report', this.post.id);
    },
  },
};
