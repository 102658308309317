




































































import AulaButton from '../../../shared/components/AulaButton.vue';
import { PropType } from 'vue';
import { PostModel } from '../../../shared/models/post.model';
import { AddCommentRequest } from '../../../shared/models/requests/addComment.request';
import { commentTypes } from '../../../shared/enums/commentTypes';
import { commentService } from '../../../shared/services/api/comment.service';
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types.js';
import { GetCommentsRequest } from '../../../shared/models/requests/getComments.request';
import { CommentModel } from '../../../shared/models/comment.model';
import PostComment from './PostComment.vue';
import Icon from '../../../shared/components/Icon.vue';
import { iconClassEnum } from '../../../shared/enums/iconClassEnum';
import CommentTextarea from '../comments/CommentTextarea.vue';
import { InstitutionModel } from '../../../shared/models/institution.model';
import { portalRoles } from '../../../shared/enums/portalRoles';

export default {
  components: { CommentTextarea, Icon, PostComment, AulaButton },
  props: {
    post: { type: Object as PropType<PostModel>, required: true },
    includesReportedComments: { type: Boolean, default: false },
    previewMode: { type: Boolean, default: false },
    highlightedCommentId: { type: Number, default: null },
  },
  data() {
    return {
      isExpanded: false,
      isLoading: false,
      limit: 5,
      index: 0,
      comments: [] as CommentModel[],
      commentText: '',
      selectedProfile: null as InstitutionModel,
    };
  },
  computed: {
    ...mapGetters({
      institutions: types.GET_INSTITUTIONS,
      activeInstitutionCodes: types.GET_ACTIVE_INSTITUTIONS,
    }),
    canSelectInstitution() {
      return this.post.relatedProfiles.length > 1 && this.selectedProfile?.role === portalRoles.EMPLOYEE;
    },
    canComment() {
      return this.post.canCurrentUserComment && !this.previewMode;
    },
    iconClassEnum() {
      return iconClassEnum;
    },
    buttonTextComments() {
      let suffix = this.$options.filters.fromTextKey('COMMENTS_COMMENTS');
      if (this.post.commentCount === 1) {
        suffix = this.$options.filters.fromTextKey('COMMENTS_COMMENT');
      }
      return `${this.post.commentCount} ${suffix}`;
    },
    institutionProfileId() {
      const relatedInstitution = this.selectedProfile?.institution;
      const institutionProfile = this.institutions.find(
        institution => institution.institutionCode === relatedInstitution?.institutionCode
      );
      return institutionProfile?.institutionProfileId || null;
    },
    totalCount() {
      let count = 0;
      for (const comment of this.comments) {
        count = count + 1 + comment.commentCount;
      }
      return count;
    },
    hasMoreComments() {
      return this.totalCount < this.post.commentCount;
    },
  },
  mounted() {
    this.selectedProfile = this.post.relatedProfiles[0];
  },
  methods: {
    selectProfile(profile) {
      this.selectedProfile = profile;
    },
    async expandCommentArea() {
      this.isExpanded = true;
      this.comments = await this.getComments();
    },
    handleWriteCommentClicked() {
      if (!this.isExpanded) {
        this.expandCommentArea();
      }
      this.$nextTick(() => {
        this.$refs.textarea.focus();
      });
    },
    async handleAddComment() {
      const newComment = await this.addComment();
      this.comments.push(newComment);
      this.resetInput();
    },
    async getComments() {
      const params: GetCommentsRequest = {
        parentType: commentTypes.POST,
        parentId: this.post.id,
        limit: this.limit,
        startIndex: this.index,
        includeReportedComments: this.includesReportedComments,
      };
      this.isLoading = true;
      const { comments } = await commentService.getComments(params);
      this.isLoading = false;
      return comments;
    },
    async addComment() {
      const payload: AddCommentRequest = {
        creatorInstProfileId: this.institutionProfileId,
        commentableItem: {
          id: this.post.id,
          type: commentTypes.POST,
        },
        content: this.commentText,
      };
      return await commentService.addComment(payload);
    },
    async loadPreviousComments() {
      this.index = this.comments.length;
      const previousComments = await this.getComments();
      this.comments.unshift(...previousComments);
    },
    resetInput() {
      this.commentText = '';
    },
  },
};
