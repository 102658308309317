<template>
  <div class="filepicker-container">
    <b-button
      v-if="openFileDialogDirectly"
      variant="link"
      class="pull-left"
      :aria-label="'ARIA_LABEL_ATTACH_FILES' | fromTextKey"
      @click="$refs.uploader.click()"
    >
      <i :class="icon" />
      <span class="active-upload-link">{{ buttonText | fromTextKey }}</span>
    </b-button>
    <b-dropdown
      v-else
      class="dropup"
      menu-class="file-picker-dropdown"
      dropup
      boundary="viewport"
      variant="link"
      no-caret
      :popper-opts="{ positionFixed: true }"
      @hidden="hideDropUp"
    >
      <template slot="button-content">
        <div class="d-flex align-items-center">
          <i :class="icon" aria-hidden="true" />
          <span class="active-upload-link">{{ buttonText | fromTextKey }}</span>
        </div>
      </template>
      <b-dropdown-item href="#" @click="$refs.uploader.click()">
        <i class="icon-Aula_Computer" aria-hidden="true" />{{ 'FILEPICKER_SECURE_MY_COMPUTER' | fromTextKey }}
      </b-dropdown-item>
      <b-dropdown-item v-if="uploadFromGallery" href="#" @click="$emit('openGallery')">
        <i class="icon-Aula_picture" aria-hidden="true" />{{ 'FILEPICKER_GALLERY' | fromTextKey }}
      </b-dropdown-item>
      <b-dropdown-item
        v-if="canImportFromOneDrive"
        id="attach-onedrive-files"
        href="#"
        @click="onClickExternalService('onedrive', 'download')"
      >
        <i class="icon-onedrive" aria-hidden="true" />{{ 'ATTACH_ONEDRIVE_FILES_TITLE' | fromTextKey }}
        <b-tooltip target="attach-onedrive-files" triggers="hover focus" :placement="tooltipPlacement">
          <strong>{{ 'LINK_OR_ATTACH_EXTERNAL_TOOLTIP_HEADER' | fromTextKey }}:</strong>
          {{ 'LINK_OR_ATTACH_EXTERNAL_TOOLTIP_BODY' | fromTextKey }}
        </b-tooltip>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="canAttachFromOneDrive"
        id="link-onedrive-files"
        href="#"
        @click="onClickExternalService('onedrive', 'share')"
      >
        <i class="icon-onedrive" aria-hidden="true" />{{ 'DOCUMENTS_TYPES_ONEDRIVE_FILES_TITLE_LINK' | fromTextKey }}
        <b-tooltip target="link-onedrive-files" triggers="hover focus" :placement="tooltipPlacement">
          <strong>{{ 'LINK_OR_ATTACH_EXTERNAL_TOOLTIP_HEADER' | fromTextKey }}</strong>
          {{ 'LINK_OR_ATTACH_EXTERNAL_TOOLTIP_BODY' | fromTextKey }}
        </b-tooltip>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="canImportFromGoogleDrive"
        id="attach-googledrive-files"
        href="#"
        @click="onClickExternalService('googledrive', 'download')"
      >
        <i class="icon-googledrive" aria-hidden="true" />{{ 'ATTACH_GOOGLE_FILES_TITLE' | fromTextKey }}
        <b-tooltip target="attach-googledrive-files" triggers="hover focus" :placement="tooltipPlacement">
          <strong>{{ 'LINK_OR_ATTACH_EXTERNAL_TOOLTIP_HEADER' | fromTextKey }}</strong>
          {{ 'LINK_OR_ATTACH_EXTERNAL_TOOLTIP_BODY' | fromTextKey }}
        </b-tooltip>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="canAttachFromGoogleDrive"
        id="link-googledrive-files"
        href="#"
        @click="onClickExternalService('googledrive', 'share')"
      >
        <i class="icon-googledrive" aria-hidden="true" />{{ 'DOCUMENTS_TYPES_GOOGLE_FILES_TITLE_LINK' | fromTextKey }}
        <b-tooltip target="link-googledrive-files" triggers="hover focus" :placement="tooltipPlacement">
          <strong>{{ 'LINK_OR_ATTACH_EXTERNAL_TOOLTIP_HEADER' | fromTextKey }}</strong>
          {{ 'LINK_OR_ATTACH_EXTERNAL_TOOLTIP_BODY' | fromTextKey }}
        </b-tooltip>
      </b-dropdown-item>
      <b-dropdown-item v-if="uploadFromFilesharing" href="#" @click="$emit('openDocumentPicker')">
        <i class="icon-Aula_paper" aria-hidden="true" />{{ 'FILEPICKER_SECURE_FILESHARING' | fromTextKey }}
      </b-dropdown-item>
      <div class="arrow-down-outer" />
    </b-dropdown>
    <input id="fileInput" ref="uploader" type="file" style="display: none" :multiple="multiple" @change="uploadFile" />
    <aula-modal
      ref="cannotUploadGoogleDocsModal"
      :show-cancel="false"
      @okClicked="$refs.cannotUploadGoogleDocsModal.hide()"
    >
      {{ 'FILEPICKER_UPLOAD_GOOGLE_DOCS_WARNING' | fromTextKey }} <br />
      {{ failingGoogleDocs.join(', ') }}
    </aula-modal>
    <aula-modal
      ref="thirdPartyCookieError"
      header-text="ERROR_MODAL"
      :show-cancel="false"
      @okClicked="hideThirdPartyCookieError()"
    >
      {{ 'ERROR_MISSING_THIRD_PARTY_COOKIES' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="externalLinkWarningModal"
      header-text="LINK_OR_ATTACH_EXTERNAL_TOOLTIP_HEADER"
      @cancelClicked="$refs.externalLinkWarningModal.hide()"
      @okClicked="
        handleExternalService($refs.externalLinkWarningModal.$data.service, $refs.externalLinkWarningModal.$data.type);
        $refs.externalLinkWarningModal.hide();
      "
    >
      {{ 'LINK_OR_ATTACH_EXTERNAL_TOOLTIP_BODY' | fromTextKey }}<br /><br />
      {{ 'LINK_OR_ATTACH_EXTERNAL_TOOLTIP_MOBILE_CONFIRM' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import { types } from '../../src_adm/store/types/types';
import { permissionEnum } from '../enums/permissionEnum.ts';
import { parentTypes } from '../enums/parentTypes.ts';
import { mapGetters, mapActions } from 'vuex';
import JSZipUtils from 'jszip-utils';
import { breakpoints } from '../enums/breakpoints';

export default {
  name: 'FilePicker',
  props: {
    parent: { type: String, default: parentTypes.GALLERY },
    uploadFromFilesharing: { type: Boolean, default: false },
    showAttachFromOneDrive: { type: Boolean, default: true },
    showAttachFromGoogleDrive: { type: Boolean, default: true },
    uploadFromGallery: { type: Boolean, default: true },
    uploadFromOneDrive: { type: Boolean, default: true },
    uploadFromGoogleDrive: { type: Boolean, default: true },
    openFileDialogDirectly: { type: Boolean, default: false },
    multiple: { type: Boolean, default: true },
    icon: { type: String, default: 'icon-texteditor_attach' },
    buttonText: { type: String, default: 'UPLOAD_BUTTON_LABEL' },
  },
  data() {
    return {
      permissionEnum: permissionEnum,
      parentTypes: parentTypes,
      pickerApiLoaded: false,
      googleDriveToken: null,
      oneDriveToken: '',
      attachFileAction: 'share',
      failingGoogleDocs: [],
      tokenClient: null,
    };
  },
  computed: {
    ...mapGetters({
      folders: types.GETTER_FOLDERS,
      hasPermission: types.HAS_PERMISSION,
      limitOfUploadingFileSize: types.GET_LIMIT_OF_UPLOADING_FILE_SIZE,
    }),
    canAttachFromOneDrive() {
      if (!this.showAttachFromOneDrive) {
        return false;
      }
      return (
        this.uploadFromOneDrive &&
        (this.hasPermission(permissionEnum.ATTACH_ONEDRIVE_FILE) ||
          this.hasPermission(permissionEnum.IMPORT_MEDIA_FROM_ONEDRIVE)) &&
        this.parent != parentTypes.GALLERY
      );
    },
    canImportFromOneDrive() {
      return (
        this.uploadFromOneDrive &&
        ((this.hasPermission(permissionEnum.IMPORT_MEDIA_FROM_ONEDRIVE) && this.parent == parentTypes.GALLERY) ||
          (this.hasPermission(permissionEnum.ATTACH_ONEDRIVE_FILE) && this.parent != parentTypes.GALLERY))
      );
    },
    canAttachFromGoogleDrive() {
      if (!this.showAttachFromGoogleDrive) {
        return false;
      }
      return (
        this.uploadFromGoogleDrive &&
        (this.hasPermission(permissionEnum.ATTACH_GOOGLE_DRIVE_FILE) ||
          this.hasPermission(permissionEnum.IMPORT_MEDIA_FROM_GOOGLE_DRIVE)) &&
        this.parent != parentTypes.GALLERY
      );
    },
    canImportFromGoogleDrive() {
      return (
        this.uploadFromGoogleDrive &&
        ((this.hasPermission(permissionEnum.IMPORT_MEDIA_FROM_GOOGLE_DRIVE) && this.parent == parentTypes.GALLERY) ||
          (this.hasPermission(permissionEnum.ATTACH_GOOGLE_DRIVE_FILE) && this.parent != parentTypes.GALLERY))
      );
    },
    tooltipPlacement() {
      // If there is enough space, show tooltip on the right. Otherwise, on top.
      if (window.innerWidth > breakpoints.MOBILE_BREAKPOINT) {
        return 'right';
      } else {
        return 'top';
      }
    },
  },
  methods: {
    ...mapActions({}),
    hideDropUp() {
      this.$emit('hidden');
    },
    onClickExternalService(service, type) {
      if (window.innerWidth < breakpoints.MOBILE_BREAKPOINT) {
        const modal = this.$refs.externalLinkWarningModal;
        modal.$data.service = service;
        modal.$data.type = type;
        this.$refs.externalLinkWarningModal.show();
      } else {
        this.handleExternalService(service, type);
      }
    },
    handleExternalService(service, type) {
      if (service === 'googledrive' && type === 'share') {
        this.launchGoogleDrivePicker('share');
      } else if (service === 'googledrive' && type === 'download') {
        this.launchGoogleDrivePicker('download');
      } else if (service === 'onedrive' && type === 'share') {
        this.launchOneDrivePicker('share');
      } else if (service === 'onedrive' && type === 'download') {
        this.launchOneDrivePicker('download');
      } else {
        // TODO: Consider error handling?
      }
    },
    launchOneDrivePicker(action) {
      this.attachFileAction = action;
      let clientId = '5e941989-1b19-4d2e-b53e-43c874ce2898'; // Localhost
      if (location.host.includes('preprod')) {
        clientId = 'bf40aa71-5321-4b56-a2ff-a2e5b8189ded';
      } else if (location.host.includes('aula.dk')) {
        clientId = 'c5d4fb54-a6a3-4308-9ddf-5392e38ef785';
      } else if (location.host.includes('test1')) {
        clientId = '11062905-d7e6-4eb9-843c-89b43d8e2bb2';
      } else if (location.host.includes('test3')) {
        clientId = '3346359b-2871-4d7a-a9c1-5e427f415196';
      }
      const odOptions = {
        clientId: clientId,
        action: action,
        multiSelect: true,
        advanced: {
          redirectUri: 'https://' + location.host + '/static/onedrive_picker_redirect.html',
        },
        success: files => {
          this.oneDriveToken = files.accessToken;
          this.uploadFilesFromOneDrive(files.value);
        },
        cancel: function () {
          /* cancel handler */
        },
        error: function () {
          /* error handler */
        },
      };
      // eslint-disable-next-line no-undef
      OneDrive.open(odOptions);
    },
    launchGoogleDrivePicker(action) {
      this.attachFileAction = action;

      // Use Google Identity Services to request an access token
      this.tokenClient.callback = async response => {
        if (response.error !== undefined) {
          throw response;
        }
        this.googleDriveToken = response.access_token;
        this.createPicker();
      };

      if (this.googleDriveToken === null) {
        // Prompt the user to select a Google Account and ask for consent to share their data
        // when establishing a new session.
        this.tokenClient.requestAccessToken({ prompt: 'consent' });
      } else {
        // Skip display of account chooser and consent dialog for an existing session.
        this.tokenClient.requestAccessToken({ prompt: '' });
      }
    },
    onPickerApiLoad() {
      this.pickerApiLoaded = true;
    },
    initGis() {
      // The Client ID obtained from the Google API Console. Replace with your own Client ID.
      const clientId = '815650226549-no3fht64b9odsqo1bakta8voc8hh09jl.apps.googleusercontent.com';
      // Scope to use to access user's Drive items.
      const scope = 'https://www.googleapis.com/auth/drive.readonly';
      // eslint-disable-next-line no-undef
      this.tokenClient = google.accounts.oauth2.initTokenClient({
        client_id: clientId,
        scope: scope,
        callback: '', // defined later
      });
    },
    createPicker() {
      // The Browser API key obtained from the Google API Console.
      // Replace with your own Browser API key, or your own key.
      const developerKey = 'AIzaSyB6doOoKN_4Zt9XKMj7a7JFan0bnmPu3qM';
      // Replace with your own project number from console.developers.google.com.
      // See "Project number" under "IAM & Admin" > "Settings"
      if (this.pickerApiLoaded && this.googleDriveToken) {
        // eslint-disable-next-line no-undef
        const picker = new google.picker.PickerBuilder()
          .addView(
            this.attachFileAction == 'download'
              ? this.parent === parentTypes.GALLERY
                ? // eslint-disable-next-line no-undef
                  new google.picker.DocsView(google.picker.ViewId.DOCS_IMAGES_AND_VIDEOS)
                    .setParent('root')
                    .setIncludeFolders(true)
                : // eslint-disable-next-line no-undef
                  new google.picker.DocsView(google.picker.ViewId.DOCS).setParent('root').setIncludeFolders(true)
              : // eslint-disable-next-line no-undef
                new google.picker.DocsView(google.picker.ViewId.DOCS).setParent('root').setIncludeFolders(true)
          )
          .addView(
            this.attachFileAction == 'download'
              ? this.parent == parentTypes.GALLERY
                ? // eslint-disable-next-line no-undef
                  new google.picker.DocsView(google.picker.ViewId.DOCS_IMAGES_AND_VIDEOS)
                    .setEnableDrives(true)
                    .setIncludeFolders(true)
                : // eslint-disable-next-line no-undef
                  new google.picker.DocsView(google.picker.ViewId.DOCS).setEnableDrives(true).setIncludeFolders(true)
              : // eslint-disable-next-line no-undef
                new google.picker.DocsView(google.picker.ViewId.DOCS).setEnableDrives(true).setIncludeFolders(true)
          )
          .setOAuthToken(this.googleDriveToken)
          .setDeveloperKey(developerKey)
          .setLocale('da')
          // eslint-disable-next-line no-undef
          .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
          // eslint-disable-next-line no-undef
          .enableFeature(google.picker.Feature.SUPPORT_DRIVES)
          .setCallback(this.pickerCallback)
          .build();
        picker.setVisible(true);
      }
    },
    pickerCallback(data) {
      // eslint-disable-next-line no-undef
      if (data.action == google.picker.Action.PICKED) {
        this.uploadFilesFromGoogle(data.docs);
      }
    },
    showThirdPartyCookieWarning() {
      this.$refs.thirdPartyCookieError.show();
    },
    hideThirdPartyCookieError() {
      this.$refs.thirdPartyCookieError.hide();
    },
    uploadFile(event) {
      const files = event.target.files;
      this.$emit('filesAdded', files);
      this.$refs.uploader.value = '';
    },
    uploadFilesFromGoogle(files) {
      if (this.attachFileAction == 'download') {
        this.downloadGoogleFiles(files);
      } else {
        for (const file of files) {
          file.size = file.sizeBytes;
          file.accessToken = this.googleDriveToken;
          file.externalFileId = file.id;
          file.source = 'Google Drive';
        }
        this.$emit('fileLinksAdded', files);
      }
    },
    downloadGoogleFiles(files) {
      const googleFiles = [];
      let counter = 0;
      this.failingGoogleDocs = [];
      const xhr = [];
      const bytes = [];
      const fileObj = [];
      const blob = [];
      const getUrl = [];
      for (let i = 0; i < files.length; i++) {
        if (files[i].mimeType && files[i].mimeType.includes('google-apps')) {
          this.failingGoogleDocs.push(files[i].name);
          continue;
        }
        if (files[i].sizeBytes >= this.limitOfUploadingFileSize) {
          files[i].size = files[i].sizeBytes;
          files[i].externalFileId = files[i].id;
          files[i].isLargeFile = true;
          files[i].accessToken = this.googleDriveToken;
          files[i].isFromGoogleDrive = true;
          files[i].multipartUploadingInfo = {
            numberOfPart: Math.ceil(files[i].sizeBytes / this.limitOfUploadingFileSize),
          };
          counter++;
          googleFiles.push(files[i]);
          if (counter == files.length) {
            this.$emit('filesAdded', googleFiles);
          }
        } else {
          xhr[i] = new XMLHttpRequest();
          getUrl[i] = 'https://www.googleapis.com/drive/v3/files/' + files[i].id + '?alt=media';
          xhr[i].open('GET', getUrl[i], true);
          xhr[i].setRequestHeader('Authorization', 'Bearer ' + this.googleDriveToken);
          xhr[i].setRequestHeader('Cache-Control', 'no-cache');
          xhr[i].responseType = 'arraybuffer';
          xhr[i].onreadystatechange = () => {
            if (xhr[i].readyState == 4) {
              bytes[i] = new Uint8Array(xhr[i].response);
              fileObj[i] = new File([bytes[i]], files[i].name);
              blob[i] = new Blob([bytes[i]]);
              fileObj[i].url = blob[i];
              fileObj[i].isLargeFile = false;
              if (fileObj[i].size == files[i].sizeBytes) {
                googleFiles.push(fileObj[i]);
                counter++;
                if (counter == files.length) {
                  this.$emit('filesAdded', googleFiles);
                }
              }
            }
          };
          xhr[i].send();
        }
      }
      if (this.failingGoogleDocs.length > 0) {
        this.$refs.cannotUploadGoogleDocsModal.show();
      }
    },
    uploadFilesFromOneDrive(files) {
      const onedriveFiles = [];
      if (this.attachFileAction == 'download') {
        let counter = 0;
        for (let i = 0; i < files.length; i++) {
          if (files[i].size >= this.limitOfUploadingFileSize) {
            files[i].externalFileId = files[i].id;
            files[i].isLargeFile = true;
            files[i].isFromOneDrive = true;
            files[i].multipartUploadingInfo = {
              numberOfPart: Math.ceil(files[i].size / this.limitOfUploadingFileSize),
            };
            counter++;
            onedriveFiles.push(files[i]);
            if (counter == files.length) {
              this.$emit('filesAdded', onedriveFiles);
            }
          } else {
            JSZipUtils.getBinaryContent(files[i]['@microsoft.graph.downloadUrl'], (err, data) => {
              const fileObj = new File([data], files[i].name);
              onedriveFiles.push(fileObj);
              counter++;
              if (counter == files.length) {
                this.$emit('filesAdded', onedriveFiles);
              }
            });
          }
        }
      } else {
        for (const file of files) {
          file.accessToken = this.oneDriveToken;
          file.externalFileId = file.id;
          file.source = 'OneDrive';
        }
        this.$emit('fileLinksAdded', files);
      }
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    gapi.load('picker', this.onPickerApiLoad());

    this.initGis();
  },
};
</script>

<style scoped lang="scss">
@import '../assets/scss/core/variables.scss';
@import '../assets/scss/core/breakpoints';

.modal-aula /deep/ .modal-header {
  display: none;
}

.arrow-down-outer {
  position: absolute;
  left: 10px;
  border-bottom: solid 13px $color-white;
  border-left: solid 13px transparent;
  border-right: solid 13px transparent;
}

.dropdown-menu[x-placement^='bottom'] {
  .arrow-down-outer {
    top: -11px;
  }
}

.dropdown-menu[x-placement^='top'] {
  .arrow-down-outer {
    bottom: -11px;
    transform: rotate(0.5turn);
  }
}

.dropup {
  i {
    font-size: 22px;
    margin-right: 10px;
    float: left;
    padding: 1px 0 1px 1px;

    &.icon-Aula_plus {
      font-size: 12px;
      padding: 5px;
      border-radius: 50%;
      color: var(--color-white);
      background-color: var(--color-primary-darker);
    }
  }
  a {
    .theme-employee & {
      color: $color-primary-darker-employee;
    }
    .theme-guardian & {
      color: $color-primary-darker-guardian;
    }
    .theme-child & {
      color: $color-primary-darker-child;
    }
    .theme-admin & {
      color: $color-primary-darker-admin;
    }
    padding-left: 1rem;
    padding-right: 4rem;
  }
  .dropdown-item {
    outline: none;
  }
}
</style>
