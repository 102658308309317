<template>
  <portal to="multiButtonMobile">
    <div
      v-if="!showItems"
      class="multi-button tool-link"
      :class="{ 'in-group': activeGroup.id != null }"
      tabindex="0"
      role="button"
      :aria-label="'ARIA_LABEL_CREATE_BUTTON' | fromTextKey"
      @click="items.length === 1 ? emitAction(items[0].action) : show()"
      @keydown.enter="items.length === 1 ? emitAction(items[0].action) : show()"
      @keydown.space="items.length === 1 ? emitAction(items[0].action) : show()"
    >
      <div v-if="!hideMultiButton" class="aula-circle-large dropup-menu">
        <div class="aula-circle-small">
          <i class="icon" :class="getIcon()" />
        </div>
      </div>
    </div>
    <div v-else class="multi-button">
      <div
        v-for="(item, index) in filteredItems"
        :key="index"
        class="tool-link tool-link-white"
        :class="{ active: showItems, 'in-group': activeGroup.id != null, 'no-icon': !hasSpaceForIcons }"
        :style="[baseStyle(index)]"
        tabindex="0"
        :aria-label="item.label != null ? $options.filters.fromTextKey(item.label) : ''"
        role="button"
        @click="emitAction(item.action)"
        @keydown.enter="emitAction(item.action)"
      >
        <div class="tool-link-text" :aria-hidden="item.label != null">
          {{ item.text | fromTextKey }}
        </div>
        <div class="aula-circle-large">
          <div class="aula-circle-small">
            <i v-if="!isChild || item.icon === 'el-icon-download'" class="icon" :class="item.icon" />
            <AulaImg v-else class="icon" :img-url="getEarlyStudentIcon(item.icon)" />
          </div>
        </div>
      </div>
      <div
        id="tool-link-dropup"
        class="tool-link"
        :class="{ active: showItems, 'in-group': activeGroup.id != null }"
        role="button"
        tabindex="0"
        :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
        @click="hide"
        @keydown.enter="hide"
        @keydown.space="hide"
      >
        <div class="aula-circle-large dropup-menu">
          <div class="aula-circle-small">
            <i class="icon icon-Aula_close" />
          </div>
        </div>
      </div>
      <div
        class="multi-button-backdrop modal-backdrop fade show"
        :class="activeGroup.id != null ? 'in-group' : ''"
        @click="hide"
      />
    </div>
  </portal>
</template>

<script>
import { mapGetters } from 'vuex';
import { types } from '../../store/types/types';
import { institutionRole } from '../../../shared/enums/institutionRole';
import { displayMode } from '../../../shared/enums/displayMode';
import AulaImg from '../../../shared/components/AulaImg';
import $ from 'jquery';

export default {
  components: { AulaImg },
  props: {
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      institutionRole: institutionRole,
      showItems: false,
      actionShowIcon: ['activateDownloadMedia', 'activateDownloadAlbums'],
      hasSpaceForIcons: true,
      itemNoIconHeight: 35,
      itemWithIconHeight: 60,
    };
  },
  computed: {
    ...mapGetters({
      isPreviewMode: types.GET_IS_PREVIEW_MODE,
      hasPermission: types.HAS_PERMISSION,
      profile: types.GET_CURRENT_PROFILE,
      isMobile: types.GET_IS_MOBILE,
      isTablet: types.GET_IS_TABLET,
      activeGroup: types.GET_ACTIVE_GROUP,
      showGroupsDropdown: types.GET_SHOW_GROUPS_DROPDOWN,
      showSearchDropdown: types.GET_SHOW_SEARCH_DROPDOWN,
      showProfileDropdown: types.GET_SHOW_PROFILE_DROPDOWN,
      pageConfiguration: types.GET_PAGE_CONFIGURATION,
    }),
    isChild() {
      return this.profile.institutionRoles.includes(this.institutionRole.EARLY_STUDENT);
    },
    hideMultiButton() {
      return this.showProfileDropdown || this.showSearchDropdown;
    },
    filteredItems() {
      const filteredItems = [];
      for (const item of this.items) {
        if (this.hasOneOfTheFollowingPermissions(item.permissions) && this.hasModule(item.module)) {
          filteredItems.unshift(item);
        }
      }
      return filteredItems;
    },
    itemHeight() {
      let base = this.itemNoIconHeight;
      if (this.hasSpaceForIcons) {
        base = this.itemWithIconHeight;
      }
      return base;
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateHasSpaceForIcons);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateHasSpaceForIcons);
  },
  methods: {
    hasModule(moduleType) {
      if (!moduleType) {
        return true;
      }
      if (this.pageConfiguration.moduleConfigurations != null) {
        for (const item of this.pageConfiguration.moduleConfigurations) {
          if (item.module.type === moduleType && item.aggregatedDisplayMode === displayMode.SHOWN) {
            return true;
          }
        }
      }
      return false;
    },
    hasOneOfTheFollowingPermissions(permissions) {
      if (!permissions) {
        return true;
      }
      for (const permission of permissions) {
        if (this.hasPermission(permission)) {
          return true;
        }
      }
      return false;
    },
    getEarlyStudentIcon(icon) {
      return 'static/icons/' + icon + '_child.svg';
    },
    baseStyle(index) {
      const itemHeight = this.itemHeight;
      return { bottom: 130 + index * itemHeight + 'px' };
    },
    emitAction(action) {
      $('.modal-aula').scrollTop(0);
      if (!this.isPreviewMode) {
        this.$emit(action);
        this.hide();
      }
    },
    getIcon() {
      if (this.items.length == 1 && this.actionShowIcon.includes(this.items[0].action)) {
        return this.items[0].icon + ' actionIcon';
      } else {
        return 'icon-Aula_plus';
      }
    },
    hide() {
      this.showItems = false;
    },
    show() {
      this.showItems = true;
      setTimeout(() => this.updateHasSpaceForIcons());
    },
    updateHasSpaceForIcons() {
      const windowHeight = window.innerHeight;
      const itemsLength = this.filteredItems.length;
      const itemsHeight = itemsLength * this.itemWithIconHeight;
      const footerHeight = this.getFooterHeight();
      const headerHeight = this.getHeaderHeight();
      const dropUpButtonHeight = this.getDropUpButtonHeight();
      const contentHeight = windowHeight - headerHeight - footerHeight - dropUpButtonHeight;
      this.hasSpaceForIcons = itemsHeight <= contentHeight;
    },
    getFooterHeight() {
      const footer = document.getElementById('aula-menu');
      return footer.clientHeight;
    },
    getHeaderHeight() {
      const header = document.getElementById('aula-header');
      return header.clientHeight;
    },
    getDropUpButtonHeight() {
      const dropup = document.getElementById('tool-link-dropup');
      return dropup?.clientHeight || 0;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';

.tool-link {
  z-index: $multi-button-mobile-container-z-index;
  &.in-group {
    z-index: $multi-button-mobile-in-group-container-z-index;
  }

  &.no-icon {
    .aula-circle-large:not(.dropup-menu) {
      display: none;
    }

    .tool-link-text {
      position: unset;
    }
  }
}
.multi-button-backdrop {
  z-index: $multi-button-mobile-backdrop-container-z-index;
  &.in-group {
    z-index: $multi-button-mobile-in-group-backdrop-container-z-index;
  }
}

.tool-link-text {
  position: absolute;
  right: 77px;
  top: 15px;
  width: 195px;
  font-size: 16px;
  color: $color-white;
  text-transform: uppercase;
  text-align: right;
}

.aula-circle-large .aula-circle-small .icon {
  top: 15px;

  &.actionIcon {
    top: 14px;
    font-size: 25px;
    margin-right: 0;
  }
}

.tool-link-white {
  .aula-circle-large,
  .aula-circle-small {
    background: $color-white;
    .icon {
      color: $color-darkblue;
      font-size: 25px;
      top: 10px;
    }
  }
}

.aula-circle-large {
  height: 55px;
  width: 55px;
}

.aula-circle-small {
  height: 45px;
  width: 45px;
  top: 5px;
  left: 5px;
}

.modal-aula-backdrop.show {
  opacity: 1;
}

img.icon {
  position: absolute;
  width: 38px;
  left: 8px;
  top: 10px !important;
}
</style>
