import { render, staticRenderFns } from "./CommentTextarea.vue?vue&type=template&id=38d99fa5&scoped=true&"
import script from "./CommentTextarea.vue?vue&type=script&lang=ts&"
export * from "./CommentTextarea.vue?vue&type=script&lang=ts&"
import style0 from "./CommentTextarea.vue?vue&type=style&index=0&id=38d99fa5&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38d99fa5",
  null
  
)

export default component.exports