<template>
  <div class="clearfix">
    <b-row>
      <b-col class="file-picker-col d-flex align-items-center" :class="{ 'has-help-box': tooltipText != null }">
        <file-picker
          ref="filePicker"
          :style="!fileFormatsLoaded || !maxFileSizeLoaded ? 'display:none' : ''"
          :parent="parent"
          :icon="icon"
          :open-file-dialog-directly="openFileDialogDirectly"
          :button-text="buttonText"
          :upload-from-filesharing="uploadFromFilesharing"
          :upload-from-gallery="uploadFromGallery"
          :show-attach-from-google-drive="showAttachFromGoogleDrive"
          :show-attach-from-one-drive="showAttachFromOneDrive"
          @filesAdded="filesAdded"
          @fileLinksAdded="fileLinksAdded"
          @openGallery="$refs.galleryModal.show()"
          @openDocumentPicker="openDocumentPicker"
        />
        <help-box
          v-if="tooltipText != null"
          id="tooltip-upload"
          :title="tooltipTitle"
          :body="$options.filters.fromTextKey(tooltipText)"
        />
      </b-col>
    </b-row>
    <b-row v-if="showErrorMaxWidthHeightBody && parent !== parentTypes.GALLERY && medias.length > 0" class="mt-2 mb-3">
      <b-col>
        <i class="icon-Aula_exclamation_sign in-alert-circle white-bg" />
        {{ 'UPLOAD_MODAL_ERROR_MAX_WIDTH_HEIGHT_BODY' | fromTextKey }}
      </b-col>
    </b-row>
    <b-row v-if="medias.length > 0" class="media-upload-preview-grid">
      <template v-for="(media, index) in medias">
        <media-data
          v-if="showUploadedFiles"
          :key="media.guid"
          class="mb-2"
          :attachment="media"
          :allow-selected="false"
          :only-tags="true"
          :show-media-data="showMediaData"
          :show-thumbnail="showThumbnail"
          :large-thumbnail="largeThumbnail"
          :parent="parent"
          :institution-code-for-tagging="institutionCodeForTagging"
          @mediaInfoUpdated="mediaInfoUpdated"
          @removeMedia="removeFiles([index])"
        />
      </template>
    </b-row>
    <slot />
    <aula-modal
      ref="galleryModal"
      css-class="modal-fullscreen gallery-modal"
      header-text="GALLERY_UPLOAD_PICTURES"
      :disable-submit="selectedMedia.length == 0 && selectedAlbums.length == 0"
      @okClicked="addMediaFromGallery"
      @cancelClicked="$refs.galleryModal.hide()"
    >
      <gallery
        :parent="parentTypes.UPLOAD"
        :institution-profile-ids-filter="galleryInstitutionProfileIds"
        @addMediaFromGallery="addMediaFromGallery"
        @selectedMediaUpdated="selectedMediaUpdated"
        @selectedAlbumsUpdated="selectedAlbumsUpdated"
      />
    </aula-modal>
    <documents-picker
      v-if="isShownDocumentPicker"
      @onSelectDocuments="attachSecureDocuments"
      @onCanceled="isShownDocumentPicker = false"
    />
    <aula-modal ref="fileErrorModal" :show-cancel="false" @okClicked="$refs.fileErrorModal.hide()">
      <template v-if="showErrorTitleLength">
        {{ 'UPLOAD_MODAL_ERROR_TITLE_IS_TOO_LONG' | fromTextKey }}<br />
        {{ 'UPLOAD_MODAL_ERROR_FILES' | fromTextKey }}
        <div v-for="(file, i) in titleFilesNotImported" :key="i">
          {{ file.name }}
        </div>
        <hr />
      </template>
      <template v-if="showErrorExtBody">
        {{ 'UPLOAD_MODAL_ERROR_EXT_BODY' | fromTextKey }}
        <div v-for="(fileFormat, i) in authorizedFileFormats" :key="i">
          {{ fileFormat.fileFormat }}
        </div>
        <br />
        {{ 'UPLOAD_MODAL_ERROR_FILES' | fromTextKey }}
        <div v-for="(file, i) in extFilesNotImported" :key="i">
          {{ file.name }}
        </div>
        <hr />
      </template>
      <template v-if="showIsNotMatchExtType">
        {{ 'UPLOAD_MODAL_ERROR_ONLY_UPLOAD_EXT_TYPE1' | fromTextKey
        }}{{ allowedExt.map(ext => '.' + ext.toString()).toString()
        }}{{ 'UPLOAD_MODAL_ERROR_ONLY_UPLOAD_EXT_TYPE2' | fromTextKey }}<br />
        {{ 'UPLOAD_MODAL_ERROR_FILES' | fromTextKey }}
        <div v-for="(file, i) in isNotMatchExtFilesNotImported" :key="i">
          {{ file.name }}
        </div>
        <hr />
      </template>
      <template v-if="showErrorNotMediaBody">
        {{ 'UPLOAD_MODAL_ERROR_NOT_MEDIA' | fromTextKey }}<br />
        <template v-for="(fileFormat, i) in allowedMediaFormats">
          <div v-if="authorizedFileFormats.find(aut => aut.fileFormat == fileFormat) != null" :key="i">
            {{ fileFormat }}
          </div> </template
        ><br />
        {{ 'UPLOAD_MODAL_ERROR_FILES' | fromTextKey }}
        <div v-for="(file, i) in notMediaFilesNotImported" :key="i">
          {{ file.name }}
        </div>
        <hr />
      </template>
      <template v-if="showErrorMaxSizeBody">
        {{ 'UPLOAD_MODAL_ERROR_MAX_SIZE_BODY' | fromTextKey }}<br />
        {{ 'UPLOAD_MODAL_ERROR_FILES' | fromTextKey }}
        <div v-for="(file, i) in maxSizeFilesNotImported" :key="i">
          {{ file.name }}
        </div>
        <hr />
      </template>
      <template v-if="showErrorMaxSizeVideo">
        {{ 'UPLOAD_MODAL_ERROR_MAX_VIDEO_SIZE' | fromTextKey({ size: videoSizeText }) }}
      </template>
      <template v-if="showErrorMaxDurationBody">
        {{ 'UPLOAD_MODAL_ERROR_MAX_DURATION_BODY' | fromTextKey }}<br />
        {{ 'UPLOAD_MODAL_ERROR_FILES' | fromTextKey }}
        <div v-for="(file, i) in maxDurationFilesNotImported" :key="i">
          {{ file.name }}
        </div>
        <hr />
      </template>
    </aula-modal>
    <aula-modal
      ref="warningMediaReachLimitNumber"
      :show-cancel="false"
      @okClicked="$refs.warningMediaReachLimitNumber.hide()"
    >
      {{ 'GALLERY_MEDIA_REACH_LIMIT_NUMBER' | fromTextKey }}
      {{ limitFileUpload }}
      {{ 'GALLERY_MEDIA_REACH_LIMIT_NUMBER_2' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="attachmentSizeFailingModal"
      header-text="ATTACHMENT_SIZE_CAUTION"
      :show-cancel="false"
      :disable-close="true"
      @okClicked="$refs.attachmentSizeFailingModal.hide()"
    >
      {{ 'ATTACHMENT_MIN_SIZE_FAILING' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="canLooseAllChangesModal"
      ok-text="BUTTON_YES"
      @cancelClicked="$refs.canLooseAllChangesModal.hide()"
      @okClicked="redirectToStepUp()"
    >
      {{ 'UPLOAD_MODAL_ERROR_LOOSE_ALL_CHANGES' | fromTextKey }}
    </aula-modal>
    <aula-modal
      ref="notAllowedToAttachMediaModal"
      :show-cancel="false"
      @okClicked="$refs.notAllowedToAttachMediaModal.hide()"
    >
      {{ 'UPLOAD_MODAL_MEDIA_NOT_ALLOWED' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapMutations } from 'vuex';
import { mapGetters } from 'vuex';
import { types } from '../../src/store/types/types';
import FilePicker from './FilePicker';
import MediaData from '../../src/components/gallery/MediaData.vue';
import DocumentsPicker from '../../src/components/documents/DocumentsPicker';
import { mediaTypes } from '../enums/mediaTypes';
import { mediaFormats } from '../enums/mediaFormats';
import { parentTypes } from '../enums/parentTypes.ts';
import { formatBytes } from '../functions/format-bytes';
import { portalRoles } from '../enums/portalRoles';
import { imageExtensions } from '../enums/imageExtensions';
import { videoExtensions } from '../enums/videoExtensions';
import { soundExtensions } from '../enums/soundExtensions';

export default {
  props: {
    showAttachFromOneDrive: { type: Boolean, default: true },
    showAttachFromGoogleDrive: { type: Boolean, default: true },
    galleryInstitutionProfileIdsFilter: { type: Array, required: false, default: () => [] },
    allowedExt: { type: Array, default: () => [] },
    allowMedia: { type: Boolean, default: true },
    mediaRemovedOutside: { type: [String, Number], default: null },
    existingMedia: { type: [Array, Object], default: null },
    showMediaData: { type: Boolean, default: true },
    showThumbnail: { type: Boolean, default: true },
    largeThumbnail: { type: Boolean, default: false },
    uploadFromGallery: { type: Boolean, default: true },
    uploadFromFilesharing: { type: Boolean, default: false },
    showUploadedFiles: { type: Boolean, default: true },
    openFileDialogDirectly: { type: Boolean, default: false },
    icon: { type: String, default: 'icon-texteditor_attach' },
    buttonText: { type: String, default: 'UPLOAD_BUTTON_LABEL' },
    tooltipText: { type: String, default: null },
    tooltipTitle: { type: String, default: '' },
    parent: { type: String, default: 'aula' },
    limitFileUpload: { type: Number, default: -1 },
    allowEmptyFiles: { type: Boolean, default: true },
    institutionCodeForTagging: { type: String, default: null },
    maxFileSizeVideo: {
      required: false,
      default: 3000,
      type: Number,
    },
  },
  data() {
    return {
      uploadedFiles: [],
      medias: [],
      uploadError: null,
      somethingWentWrong: false,
      showErrorExtBody: false,
      showErrorMaxSizeBody: false,
      showErrorMaxSizeVideo: false,
      showErrorMaxWidthHeightBody: false,
      showErrorMaxDurationBody: false,
      showErrorNotMediaBody: false,
      showIsNotMatchExtType: false,
      fileFormatsLoaded: false,
      maxFileSizeLoaded: false,
      showErrorTitleLength: false,
      selectedMedia: [],
      selectedAlbums: [],
      mediaFormats: mediaFormats,
      maxWidthHeightFilesNotImported: [],
      maxDurationFilesNotImported: [],
      extFilesNotImported: [],
      notMediaFilesNotImported: [],
      isNotMatchExtFilesNotImported: [],
      titleFilesNotImported: [],
      maxSizeFilesNotImported: [],
      parentTypes,
      allowedMediaFormats: [...imageExtensions, ...videoExtensions, ...soundExtensions],
      isShownDocumentPicker: false,
    };
  },
  computed: {
    ...mapGetters({
      authorizedFileFormats: types.GET_AUTHORIZED_FILE_FORMAT_LIST,
      isFileFormatValid: types.GET_VALID_FILE_FORMAT,
      maxFileSize: types.GET_MAX_FILE_SIZE,
      profile: types.GET_CURRENT_PROFILE,
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
      children: types.GET_CHILDREN,
    }),
    maxVideoSizeBytes() {
      return this.maxFileSizeVideo * 1024 * 1024;
    },
    videoSizeText() {
      return formatBytes(this.maxVideoSizeBytes);
    },
    galleryInstitutionProfileIds() {
      let institutionProfileIds = this.galleryInstitutionProfileIdsFilter;

      if (institutionProfileIds.length === 0 && [portalRoles.OTP, portalRoles.GUARDIAN].includes(this.profile.role)) {
        institutionProfileIds = this.children.map(child => child.id);
      }

      return institutionProfileIds;
    },
  },
  methods: {
    ...mapActions({
      fetchAuthorisedFileFormats: types.ACTIONS_CENTRAL_CONFIG_AUTHORIZED_FILE_FORMATS,
      fetchConfigMaxFileSize: types.ACTIONS_CENTRAL_CONFIG_MAX_FILE_SIZE,
      loadAlbumMedia: types.ACTION_GET_ALBUM_DETAILS,
    }),
    ...mapMutations({
      showSuccessToastr: types.MUTATE_SUCCESS_TEXT,
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
    }),
    redirectToStepUp() {
      localStorage.setItem('redirectedurlafterstepup', window.location.href);
      localStorage.setItem('redirectedurlbeforestepup', window.location.href);
      window.location.href = '/auth/stepup.php';
    },
    openDocumentPicker() {
      if (this.isSteppedUp) {
        this.isShownDocumentPicker = true;
      } else {
        if (this.parent === parentTypes.CALENDAR) {
          this.$refs.canLooseAllChangesModal.show();
        } else if (localStorage.getItem('tempDraftThread')) {
          const redirectedUrl = window.location.href.replace('opret', localStorage.getItem('tempDraftThread'));
          this.setStepUpNotification({
            showStepUpNotification: true,
            redirectedUrl: redirectedUrl,
          });
        } else {
          this.setStepUpNotification({
            showStepUpNotification: true,
            redirectedUrl: window.location.href,
          });
        }
      }
    },
    attachSecureDocuments(selectedDocuments) {
      this.$emit('attachSecureDocuments', selectedDocuments);
      this.isShownDocumentPicker = false;
    },
    copyStringToClipboard(str) {
      // Create new element
      const el = document.createElement('textarea');
      // Set value (string to be copied)
      el.value = str;
      // Set non-editable to avoid focus and move outside of view
      el.setAttribute('readonly', '');
      el.style = { position: 'absolute', left: '-9999px' };
      document.body.appendChild(el);
      // Select text inside element
      el.select();
      // Copy text to clipboard
      document.execCommand('copy');
      // Remove temporary element
      document.body.removeChild(el);
    },
    selectedMediaUpdated(media) {
      this.selectedMedia = media;
      this.selectedAlbums = [];
    },
    selectedAlbumsUpdated(albums) {
      this.selectedAlbums = albums;
      this.selectedMedia = [];
    },
    addGuidToMedia() {
      for (const med of this.medias) {
        if (med.guid == null) {
          med.guid = med.id;
        }
      }
    },
    reset() {
      if (this.existingMedia != null) {
        if (Array.isArray(this.existingMedia)) {
          this.medias = [...this.existingMedia];
        } else {
          for (const file of this.existingMedia.files) {
            this.medias.push({ id: file.id, name: file.name, file: file });
          }
          for (const link of this.existingMedia.links) {
            this.medias.push({ id: link.id, name: link.name, link: link });
          }
          for (const media of this.existingMedia.media) {
            this.medias.push({ id: media.id, name: media.name, media: media });
          }
        }
      }
      this.uploadError = null;
      this.addGuidToMedia();
    },
    removeUploadedMedia() {
      this.uploadedFiles = [];
      this.medias = [];
      this.uploadError = null;
    },
    mediaInfoUpdated(media) {
      for (const med of this.medias) {
        if (med.guid == media.guid && med.media != null && media.media != null) {
          med.media.tags = media.media.tags;
          med.media.title = media.media.title;
          med.media.rotationDegree = media.media.rotationDegree;
          med.media.updated = true;
        }
      }
      this.sendAttachments();
    },
    resetTexts() {
      this.showErrorMaxWidthHeightBody = false;
      this.showErrorMaxDurationBody = false;
      this.showErrorExtBody = false;
      this.showErrorMaxSizeBody = false;
      this.showErrorMaxSizeVideo = false;
      this.showErrorNotMediaBody = false;
      this.showIsNotMatchExtType = false;
      this.showErrorTitleLength = false;
      this.somethingWentWrong = false;

      this.maxWidthHeightFilesNotImported = [];
      this.maxDurationFilesNotImported = [];
      this.extFilesNotImported = [];
      this.notMediaFilesNotImported = [];
      this.isNotMatchExtFilesNotImported = [];
      this.titleFilesNotImported = [];
      this.maxSizeFilesNotImported = [];
      this.$emit('resetWarnings');
    },
    getExtensionFromFileName(fileName) {
      const fileNameSplit = fileName.split('.') || '';
      return fileNameSplit[fileNameSplit.length - 1].toLowerCase();
    },
    getMediaTypeFromFileName(fileName) {
      const ext = this.getExtensionFromFileName(fileName);
      let attachmentType = 'file';
      const mediaType = Vue.filter('isMedia')(ext);
      if (mediaType == mediaTypes.IMAGE || mediaType == mediaTypes.VIDEO || mediaType == mediaTypes.SOUND) {
        attachmentType = mediaType;
      }
      return attachmentType;
    },
    fileLinksAdded(files) {
      this.resetTexts();
      const newMedia = files.filter(
        file =>
          this.medias.filter(att => att.name == file.name && att.size == file.size && att.guid == file.guid).length == 0
      );
      if (this.limitFileUpload != -1 && this.medias.length + files.length > this.limitFileUpload) {
        this.$refs.warningMediaReachLimitNumber.show();
        return;
      }
      for (const file of newMedia) {
        const addMedia = {
          guid: file.id,
          name: file.name,
          size: file.size,
          link: {
            accessToken: file.accessToken,
            externalFileId: file.externalFileId,
            service: file.source,
            parentDriveId: file.parentReference != null ? file.parentReference.driveId : null,
          },
          isLoading: false,
        };
        this.medias.push(addMedia);
      }
      this.sendAttachments();
    },
    filesAdded(files) {
      this.uploadedFiles = [];
      this.resetTexts();
      if (this.limitFileUpload != -1 && this.medias.length + files.length > this.limitFileUpload) {
        this.$refs.warningMediaReachLimitNumber.show();
        return;
      }
      let counter = 0;
      const filesLength = files.length;
      for (const file of files) {
        let addFile = true;
        const ext = this.getExtensionFromFileName(file.name);
        let isForbiddenFileExtension = false;
        const type = this.getMediaTypeFromFileName(file.name);
        const isMedia = Vue.filter('isMedia')(ext);

        if (file.name.length > 100) {
          this.showErrorTitleLength = true;
          this.somethingWentWrong = true;
          this.titleFilesNotImported.push(file);
          addFile = false;
        }
        if (this.allowedExt.length > 0) {
          isForbiddenFileExtension = this.allowedExt.filter(item => item == ext).length == 0;
        }

        if (this.allowMedia === false && isMedia) {
          this.$refs.notAllowedToAttachMediaModal.show();
          addFile = false;
        }

        if (isForbiddenFileExtension && addFile) {
          this.showIsNotMatchExtType = true;
          this.somethingWentWrong = true;
          this.isNotMatchExtFilesNotImported.push(file);
          addFile = false;
        }
        if (!this.isFileFormatValid(ext) && addFile) {
          this.showErrorExtBody = true;
          this.somethingWentWrong = true;
          this.extFilesNotImported.push(file);
          addFile = false;
        }

        if (file.size > this.maxFileSize * 1024 * 1024 && type != mediaTypes.VIDEO && addFile) {
          this.showErrorMaxSizeBody = true;
          this.somethingWentWrong = true;
          this.maxSizeFilesNotImported.push(file);
          addFile = false;
        }
        if (file.size > this.maxVideoSizeBytes && type == mediaTypes.VIDEO && addFile) {
          // Video max file size is 3GB
          this.showErrorMaxSizeVideo = true;
          this.somethingWentWrong = true;
          this.maxSizeFilesNotImported.push(file);
          addFile = false;
        }
        if (this.parent == parentTypes.GALLERY && !Vue.filter('isMedia')(ext) && addFile) {
          this.showErrorNotMediaBody = true;
          this.somethingWentWrong = true;
          this.notMediaFilesNotImported.push(file);
          addFile = false;
        }
        if (!this.allowEmptyFiles && file.size === 0) {
          this.$refs.attachmentSizeFailingModal.show();
          addFile = false;
        }
        if (addFile) {
          if (type == mediaTypes.VIDEO || type == mediaTypes.SOUND) {
            const video = type === mediaTypes.SOUND ? document.createElement('audio') : document.createElement('video');
            video.preload = 'metadata';
            let duration = 0;
            if (ext == mediaFormats.mp4 && !file.isLargeFile) {
              video.onloadedmetadata = () => {
                window.URL.revokeObjectURL(video.src);
                duration = video.duration;
                file.duration = duration;
                this.uploadedFiles.push(file);
                counter++;
                if (counter == filesLength) {
                  this.addNewMedia(this.uploadedFiles);
                }
              };
            } else {
              file.duration = duration;
              this.uploadedFiles.push(file);
              counter++;
              if (counter == filesLength) {
                this.addNewMedia(this.uploadedFiles);
              }
            }
            if (!file.isLargeFile) {
              video.src = URL.createObjectURL(file);
              file.url = URL.createObjectURL(file);
            }
          } else {
            if (type == mediaTypes.IMAGE) {
              const url = URL.createObjectURL(file);
              const img = new Image();

              img.onload = () => {
                if (img.width > 1600 || img.height > 1600) {
                  this.$emit('showErrorMaxWidthHeightBody');
                  this.showErrorMaxWidthHeightBody = true;
                }
              };
              img.src = url;
              file.url = url;
            } else {
              const url = URL.createObjectURL(file);
              file.url = url;
            }
            if (addFile) {
              this.uploadedFiles.push(file);
            }
            counter++;
            if (counter == filesLength) {
              this.addNewMedia(this.uploadedFiles);
            }
          }
        } else {
          counter++;
          if (counter == filesLength) {
            this.addNewMedia(this.uploadedFiles);
          }
        }
      }
      if (this.somethingWentWrong) {
        this.$refs.fileErrorModal.show();
      }
    },
    addMediaFromGallery() {
      for (const album of this.selectedAlbums) {
        this.loadAlbumMedia({ albumId: album.id }).then(rsp => {
          if (this.limitFileUpload != -1 && this.medias.length + rsp.results.length > this.limitFileUpload) {
            this.$refs.warningMediaReachLimitNumber.show();
            return;
          }
          for (const media of rsp.results) {
            const attachment = {
              id: media.id,
              guid: media.id,
              creator: media.creator,
              albumId: this.albumId,
              fromGallery: true,
              name: media.file.name,
              media: {
                file: media.file,
                mediaType: media.mediaType,
                tags: media.tags,
                title: media.title,
                description: media.description,
              },
            };
            if (media.duration != null && media.duration != '') {
              attachment.media.duration = parseFloat(media.duration);
            }
            this.medias.push(attachment);
          }
        });
      }
      if (this.limitFileUpload != -1 && this.medias.length + this.selectedMedia.length > this.limitFileUpload) {
        this.$refs.warningMediaReachLimitNumber.show();
        return;
      }
      for (const media of this.selectedMedia) {
        const attachment = {
          id: media.id,
          guid: media.id,
          creator: media.creator,
          albumId: this.albumId,
          name: media.file.name,
          media: {
            file: media.file,
            mediaType: media.mediaType,
            tags: media.tags,
            title: media.title,
            description: media.description,
          },
        };
        if (media.duration != null && media.duration != '') {
          attachment.media.duration = parseFloat(media.duration);
        }
        this.medias.push(attachment);
      }
      this.sendAttachments();
      this.$refs.galleryModal.hide();
    },
    removeFiles(arrIndex) {
      if (arrIndex.length > 0) {
        arrIndex.sort((a, b) => (a < b ? 1 : -1));
        for (const index of arrIndex) {
          this.uploadedFiles.splice(index, 1);
          this.medias.splice(index, 1);
        }
      }
      this.sendAttachments();
    },
    addNewMedia(files) {
      const newMedia = files.filter(
        file => this.medias.filter(att => att.name == file.name && att.size == file.size).length == 0
      );
      for (const file of newMedia) {
        const guid = this.guid();
        const type = this.getMediaTypeFromFileName(file.name);

        // Find duration
        let addMedia;
        if (type == mediaTypes.VIDEO || type == mediaTypes.SOUND) {
          addMedia = {
            guid: guid,
            name: file.name,
            creator: { id: this.profile.id },
            media: {
              file: file,
              duration: file.duration,
              mediaType: type,
            },
            size: file.size,
            isLoading: false,
          };
        } else if (type == mediaTypes.IMAGE) {
          addMedia = {
            guid: guid,
            name: file.name,
            creator: { id: this.profile.id },
            media: {
              file: file,
              mediaType: type,
            },
            size: file.size,
            isLoading: false,
          };
        } else {
          // File
          addMedia = {
            guid: guid,
            name: file.name,
            creator: { id: this.profile.id },
            size: file.size,
            file: file,
            isLoading: false,
          };
        }
        this.medias.push(addMedia);
      }
      this.sendAttachments();
    },
    sendAttachments() {
      this.$emit('mediaUpdated', this.medias);
    },
    guid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    },
    populateExistingMedia() {
      if (!this.existingMedia) {
        return;
      }

      if (Array.isArray(this.existingMedia)) {
        this.medias = this.existingMedia;
        return;
      }

      if (this.existingMedia.files != null) {
        for (const file of this.existingMedia.files) {
          this.medias.push({ name: file.name, file: file });
        }
      }
      if (this.existingMedia.links != null) {
        for (const link of this.existingMedia.links) {
          this.medias.push({ name: link.name, link: link });
        }
      }
      if (this.existingMedia.media != null) {
        for (const media of this.existingMedia.media) {
          this.medias.push({ name: media.name, media: media });
        }
      }
    },
  },
  watch: {
    existingMedia() {
      this.showErrorMaxWidthHeightBody = false;
      this.populateExistingMedia();
      this.sendAttachments();
      this.addGuidToMedia();
    },
    mediaRemovedOutside() {
      this.removeFiles([this.mediaRemovedOutside]);
    },
  },
  mounted() {
    this.reset();
    if (this.authorizedFileFormats.length == 0) {
      this.fetchAuthorisedFileFormats().then(() => {
        this.fileFormatsLoaded = true;
      });
    } else {
      this.fileFormatsLoaded = true;
    }
    if (this.maxFileSize == '') {
      this.fetchConfigMaxFileSize().then(() => {
        this.maxFileSizeLoaded = true;
      });
    } else {
      this.maxFileSizeLoaded = true;
    }
    this.sendAttachments();
  },
  components: {
    FilePicker,
    MediaData,
    DocumentsPicker,
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/core/variables.scss';
@import '../assets/scss/core/breakpoints.scss';

.has-help-box {
  display: flex;
}

.file-picker-col {
  position: unset;
}

.media-upload-preview-grid {
  margin-top: 10px;
  @include breakpoint-lg() {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(425px, 1fr));
  }
}

ol {
  list-style-type: none;
  padding-left: 0;

  li {
    line-height: 53px;
    border-radius: 7px;
    background-color: #fff;
  }

  .icon {
    font-size: 20px;
    color: $color-grey-lighter;
    padding: 16px;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;

    margin-top: 2px;
    margin-right: 20px;
    float: left;
  }
  .close {
    float: right;
    margin-top: 5px;
    cursor: pointer;
    margin-right: 10px;
  }

  i {
    margin-top: 15px;
  }
}

.theme-employee {
  ol li .icon {
    background-color: $color-primary-light-employee;
  }
}

.theme-guardian,
.theme-child {
  ol li .icon {
    background-color: $color-primary-light-guardian;
  }
}
</style>
