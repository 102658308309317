<template>
  <div class="attachment-list attachment-list-variables">
    <aula-spinner v-if="isLoading" />
    <template v-for="(attachment, id) in attachments">
      <div
        v-if="
          !isLoading &&
          attachment.status != attachmentStatus.UNAVAILABLE &&
          (attachment.media == null || (showMedia && attachment.media != null))
        "
        :key="id"
        class="media-data-container"
        tabindex="0"
        role="button"
        @click="onAttachmentClick(attachment)"
        @keydown.enter="onAttachmentClick(attachment)"
        @contextmenu="onAttachmentRightClick"
      >
        <div class="album-media-data">
          <div
            class="media-item"
            :class="{
              disabled: checkIsDocumentDisabled(attachment) === true,
            }"
          >
            <div
              class="media-thumbnail"
              :class="{
                disabled: checkIsDocumentDisabled(attachment) === true,
              }"
            >
              <i
                :class="
                  attachment.link != null && attachment.link.service == 'OneDrive'
                    ? 'icon-onedrive'
                    : attachment.link != null && attachment.link.service == 'Google Drive'
                    ? 'icon-googledrive'
                    : 'icon-Aula_note'
                "
              />
            </div>
            <div class="media-name">
              <template>
                {{ getAttachmentTitle(attachment) }}
              </template>
              <template v-if="attachment.status != null && attachment.status == attachmentStatus.PENDING">
                <template v-if="attachment.media != null">
                  ({{ 'ATTACHMENT_MEDIA_STATUS_PENDING' | fromTextKey }})
                </template>
                <template v-else> ({{ 'ATTACHMENT_FILE_STATUS_PENDING' | fromTextKey }})</template>
              </template>
            </div>
            <div class="px-2">
              {{ 'OPEN' | fromTextKey }}
              <Icon class="pl-1" :name="iconClassEnum.EXPORT" />
            </div>
          </div>
        </div>
      </div>
    </template>
    <aula-modal
      ref="externalLinkWarningModal"
      @cancelClicked="$refs.externalLinkWarningModal.hide()"
      @okClicked="
        openExternalLink();
        $refs.externalLinkWarningModal.hide();
      "
    >
      {{ 'WARNING_OPEN_EXTERNAL_LINK_1' | fromTextKey }}<br /><br />
      {{ 'WARNING_OPEN_EXTERNAL_LINK_2' | fromTextKey }}
    </aula-modal>
  </div>
</template>

<script>
import { attachmentStatus } from '../../shared/enums/attachmentStatus';
import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import { types } from '../../src/store/types/types';
import Icon from './Icon.vue';
import { iconClassEnum } from '../enums/iconClassEnum';

export default {
  components: { Icon },
  props: {
    attachments: Array,
    showMedia: { type: Boolean, default: false },
  },
  data() {
    return {
      attachmentStatus: attachmentStatus,
      isLoading: false,
      externalLink: '',
      Vue: Vue,
    };
  },
  computed: {
    ...mapGetters({
      isSteppedUp: types.GET_GLOBAL_STEPPED_UP,
    }),
    iconClassEnum() {
      return iconClassEnum;
    },
  },
  methods: {
    ...mapMutations({
      setStepUpNotification: types.MUTATE_SET_NOTIFICATION_STEP_UP,
    }),
    getAttachmentTitle(attachment) {
      let attachmentTitle;
      if (attachment.document && attachment.document.isDeleted === true) {
        attachmentTitle = Vue.filter('fromTextKey')('ATTACHMENT_HAS_BEEN_DELETED');
        return attachmentTitle;
      }

      attachmentTitle = attachment.name;
      if (attachment.document != null && !attachment.document.canAccess) {
        if (this.isSteppedUp) {
          attachmentTitle = Vue.filter('fromTextKey')('ATTACHMENT_FILE_NAME_UNACCESSABLE');
        } else {
          attachmentTitle = Vue.filter('fromTextKey')('ATTACHMENT_FILE_NAME_NOT_STEPPED_UP');
        }
      }
      return attachmentTitle;
    },
    onAttachmentClick(attachment) {
      if (attachment.status === attachmentStatus.AVAILABLE) {
        if (attachment.file != null) {
          window.open(attachment.file.url);
        } else if (attachment.media != null) {
          window.open(attachment.media.file.url);
        } else if (attachment.link != null) {
          this.$refs.externalLinkWarningModal.show();
          this.externalLink = attachment.link.url;
        } else if (attachment.document != null && attachment.document.isDeleted === false) {
          if (attachment.document.canAccess) {
            this.$emit('openDocumentDrawer', {
              documentId: attachment.document.id,
              documentType: attachment.document.documentType,
            });
          } else if (!this.isSteppedUp) {
            this.setStepUpNotification({
              showStepUpNotification: true,
              redirectedUrl: window.location.href,
            });
          }
        }
      }
    },
    onAttachmentRightClick(event) {
      event.preventDefault();
    },
    openExternalLink() {
      window.open(this.externalLink);
    },
    checkIsDocumentDisabled(attachment) {
      if (attachment.document != null) {
        if (attachment.document.isDeleted === true || attachment.document.canAccess === false) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style scoped lang="scss">
@import '../assets/scss/core/variables.scss';
@import '../assets/scss/core/breakpoints.scss';

.attachment-list-variables {
  --background-color: var(--color-white);
  --thumbnail-background-color: var(--color-grey-base);
}
.attachment-list {
  display: flex;
  flex-direction: column;
  gap: 6px;
  .album-media-data {
    padding: 6px;
    background-color: var(--background-color);
    .media-item {
      display: flex;
      flex-direction: row;
      .media-thumbnail {
        width: 36px;
        height: 36px;
        padding: 8px;
        border-radius: 6px;
        background-color: var(--thumbnail-background-color);
      }
    }
  }
}
.aula-spinner {
  height: 70px;
  width: 70px;
}
.media-data-container {
  cursor: pointer;
  .disabled {
    cursor: no-drop;
  }
}
</style>
